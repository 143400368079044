import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Link } from '~/components'
import * as st from '~/assets/styl/Login.module.styl'
import logo from '~/assets/svg/logo.svg'

const ChangedPassword = ({ location }: PageProps) => {
  return (
    <>
      <Head location={location} />
      <main className="bkg-main">
        <div className={st.core}>
          <img src={logo} alt="Logo da Seventh" />
          <section>
            <h1 className="mr-10">Senha gerada com sucesso!</h1>
            <Link href="/" className="btn blue">
              Fazer meu login
            </Link>
          </section>
          <small>
            <a href="https://www.tiki.com.br/">Powered by tiki</a>
          </small>
        </div>
      </main>
    </>
  )
}

export default ChangedPassword
